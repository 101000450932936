"use-es6";

import React, { Component } from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";
import standing from "../Images/working_bal.jpg";
import crimLaw from "../Images/crim_law.jpg";

class DDUIContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section style={{ paddingTop: 64, paddingBottom: 64 }}>
        <Typography
          style={{ color: "black", fontWeight: "bold" }}
          color="inherit"
          variant="h3"
          align="center"
        >
          By your side
          <span
            style={{
              height: 4,
              width: 155,
              display: "block",
              margin: "8px auto 0",
              backgroundColor: "#4355a3",
            }}
          />
        </Typography>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          style={{ paddingBottom: 64 }}
        >
          <Grid
            item
            style={{ marginTop: 32, padding: "0px 20px" }}
            xs={12}
            md={4}
          >
            <Typography
              style={{
                color: "#28282a",
                fontWeight: "bold",
              }}
              color="inherit"
              variant="h4"
            >
              The knowledge required
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              If you are charged with a criminal offense or a DUI, you are
              facing immediate legal consequences that can negatively impact
              your life. This means that you need to equip yourself with an
              experienced and knowledgeable criminal defense attorney who will
              fight relentlessly to defend you and protect you from an unfair
              conviction or unjust penalties. No matter what the specifics of
              your situation are, I understand that you are facing a complex
              legal matter. I will help you understand your options and navigate
              through the difficulties with you. With my extensive legal
              experience on your side, you can be confident that I will
              aggressively pursue the best possible results and will provide the
              uncompromising defense that you need and deserve.
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              style={{ paddingLeft: 24, marginTop: 32 }}
              xs={12}
              md={4}
            >
              <img src={crimLaw} height={400} alt="The experience needed"></img>
            </Grid>
          </Hidden>
        </Grid>
        <div className="dashed-vertical-line"></div>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          style={{ paddingBottom: 64, paddingTop: 32 }}
        >
          <Hidden smDown>
            <Grid
              item
              style={{ padding: "0px 20px", marginTop: 32 }}
              xs={12}
              md={4}
            >
              <img src={standing} height={300} alt="Here to help you"></img>
            </Grid>
          </Hidden>
          <Grid
            item
            style={{ marginTop: 32, padding: "0px 40px" }}
            xs={12}
            md={4}
          >
            <Typography
              style={{
                color: "#28282a",
                fontWeight: "bold",
              }}
              color="inherit"
              variant="h4"
            >
              A seasoned veteran by your side
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              If you or a family member have been arrested or are under criminal
              investigation it is imperative that you retain my representation
              immediately. Having the advantage of a seasoned and experienced
              defense attorney by your side throughout the criminal legal
              process can make a significant difference in the outcome of your
              case.
            </Typography>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DDUIContent;
