"use-es6";

import React, { Component } from "react";
import {
  Container,
  Box,
  Grid,
  TextField,
  Paper,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import axios from "axios";
import ConfirmationDialog from "./ConfirmationDialog";

//const API_URL = "http://localhost:8000";
const API_URL = "https://rush-law.herokuapp.com";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      reason: "",
      description: "",
      number: "",
      open: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleContactRequest = this.handleContactRequest.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleSelectChange = (event) => {
    this.setState({ reason: event.target.value });
  };

  handleClose() {
    this.setState({ open: false });
    window.location.href = "/";
  }

  validateEmail() {
    let re = /\S+@\S+\.\S+/;
    return re.test(this.state.email);
  }

  handleContactRequest() {
    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      reason: this.state.reason,
      description: this.state.description,
      number: this.state.number,
    };
    const url = `${API_URL}/api/contact-request/`;
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (resp) => {
          this.setState({ open: true });
        },
        (error) => {
          console.warn(error);
        }
      );
  }

  render() {
    const { firstName, lastName, email, reason, number, description, open } =
      this.state;
    const firstError = firstName.trim() === "" || firstName.trim() === " ";
    const lastError = lastName.trim() === "" || lastName.trim() === " ";
    const emailError =
      email.trim() === "" || email.trim() === " " || !this.validateEmail();
    const reasonError = reason === "";
    const buttonError = firstError || lastError || emailError || reasonError;
    return (
      <div className="contact-form">
        <Container maxWidth="sm">
          <Box mt={7} mb={12}>
            <Paper style={{ padding: "64px 48px" }}>
              <Typography variant="h3" gutterBottom align="center">
                Let's get in touch
                <span
                  style={{
                    height: 4,
                    width: 135,
                    display: "block",
                    margin: "8px auto 0",
                    backgroundColor: "#4355a3",
                  }}
                />
              </Typography>
              <Typography
                variant="body2"
                align="center"
                style={{ paddingBottom: 32 }}
              >
                Or email us directly at wjrush@rushlaw.org
              </Typography>
              <Grid container spacing={2} direction="column">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      autoFocus
                      value={firstName}
                      onChange={this.handleChange("firstName")}
                      label="First name"
                      error={firstError}
                      helperText={"Required"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      value={lastName}
                      onChange={this.handleChange("lastName")}
                      label="Last name"
                      error={lastError}
                      helperText={"Required"}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    type="email"
                    value={email}
                    onChange={this.handleChange("email")}
                    fullWidth
                    label="Email"
                    required
                    error={emailError}
                    helperText={"Valid email required"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="text"
                    value={number}
                    onChange={this.handleChange("number")}
                    fullWidth
                    label="Phone Number"
                  />
                </Grid>
                <Grid item>
                  <FormControl
                    style={{ minWidth: 256 }}
                    required
                    error={reasonError}
                  >
                    <InputLabel id="reason">Reason for Legal Help</InputLabel>
                    <Select
                      id="reason"
                      autoWidth
                      value={reason}
                      onChange={this.handleSelectChange}
                    >
                      <MenuItem value={"Drug Charge"}>Drug Charge</MenuItem>
                      <MenuItem value={"DUI/DWI Charge"}>
                        DUI/DWI Charge
                      </MenuItem>
                      <MenuItem value={"Divorce"}>Divorce</MenuItem>
                      <MenuItem value={"Bankruptcy"}>Bankruptcy</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    label="Tell us about your issue"
                    multiline
                    fullWidth
                    value={description}
                    onChange={this.handleChange("description")}
                    rows={2}
                    variant="outlined"
                  />
                </Grid>
                <Grid item justify="center" style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    style={{
                      minWidth: 128,
                      color: "white",
                      backgroundColor: buttonError ? "" : "#4355a3",
                    }}
                    color="inherit"
                    onClick={this.handleContactRequest}
                    disabled={
                      firstError || lastError || emailError || reasonError
                    }
                  >
                    SEND
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <ConfirmationDialog
            open={this.state.open}
            handleClose={this.handleClose}
          />
        </Container>
      </div>
    );
  }
}

export default ContactForm;
