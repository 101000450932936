"use-es6";

import React, { Component } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import GavelIcon from "@material-ui/icons/Gavel";

class FocusArea extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="focus-area-section">
        <Container
          style={{
            marginTop: 40,
            marginBottom: 60,
            display: "flex",
            position: "relative",
          }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className="focus-item">
                <SmokingRoomsIcon fontSize="inherit" style={{ fontSize: 48 }} />
                <Typography
                  variant="h5"
                  style={{
                    marginTop: 32,
                    marginBottom: 40,
                    fontWeight: "bold",
                  }}
                >
                  CRIMINAL CHARGES
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "lighter", fontSize: "1.2rem" }}
                >
                  Being arrested or charged with a crime is the toughest time in
                  your life and you need aggressive and experienced
                  representation. Don’t leave your future to chance. Hire an
                  experienced, battle-tested criminal defense lawyer who will
                  work tirelessly on your behalf. I will carefully review your
                  case and develop a personalized strategy, tailored to you.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="focus-item">
                <AccountBalanceIcon
                  fontSize="inherit"
                  style={{ fontSize: 48 }}
                />
                <Typography
                  variant="h5"
                  style={{
                    marginTop: 32,
                    marginBottom: 40,
                    fontWeight: "bold",
                  }}
                >
                  BANKRUPTCY
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "lighter", fontSize: "1.2rem" }}
                >
                  Take the first step to overcoming debt and taking back your
                  life. Filing Bankruptcy is an important decision and can be
                  overwhelming. We will walk you through the debt relief process
                  with compassion and understanding.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="focus-item">
                <GavelIcon fontSize="inherit" style={{ fontSize: 48 }} />
                <Typography
                  variant="h5"
                  style={{
                    marginTop: 32,
                    marginBottom: 40,
                    fontWeight: "bold",
                  }}
                >
                  DIVORCE
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "lighter",
                    fontSize: "1.2rem",
                  }}
                >
                  {
                    "When a couple heads for a divorce, the balance often tilts in the favor of one partner. That’s where the expertise of our firm kicks in. We ensure that either partner is not disadvantaged in any way owing to their situations."
                  }
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    );
  }
}

export default FocusArea;
