"use-es6";

import React, { Component } from "react";
import LandingAppBar from "../Components/LandingAppBar";
import DivorceHeroContainer from "./DivorceHeroContainer";
import DivorceContent from "../Components/DivorceContent";
import PageFooter from "../Components/PageFooter";
import Legal from "../Components/Legal";

class DivorceContainer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <LandingAppBar />
        <DivorceHeroContainer />
        <DivorceContent />
        <PageFooter />
        <Legal />
      </div>
    );
  }
}

export default DivorceContainer;
