"use-es6";

import React, { Component } from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";
import cup from "../Images/money-plant.jpg";
import debt from "../Images/debt.jpg";

class BankruptcyContent extends Component {
  render() {
    return (
      <section style={{ paddingTop: 64, paddingBottom: 64 }}>
        <Typography
          style={{ color: "black", fontWeight: "bold" }}
          color="inherit"
          variant="h3"
          align="center"
        >
          What we do
          <span
            style={{
              height: 4,
              width: 155,
              display: "block",
              margin: "8px auto 0",
              backgroundColor: "#4355a3",
            }}
          />
        </Typography>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          style={{ paddingBottom: 64 }}
        >
          <Grid
            item
            style={{ marginTop: 32, padding: "0px 20px" }}
            xs={12}
            md={4}
          >
            <Typography
              style={{
                color: "#28282a",
                fontWeight: "bold",
              }}
              color="inherit"
              variant="h4"
            >
              We know how to handle debt
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              Debt issues can happen to people from all walks of life and occur
              for a variety of reasons. I will walk you through your options and
              make it easier than you ever thought to find your fresh financial
              start. I can help with your debt issues through a variety of
              options.
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              style={{ padding: "0px 20px", marginTop: 32 }}
              xs={12}
              md={4}
            >
              <img src={cup} height={300} alt="We can handle debt"></img>
            </Grid>
          </Hidden>
        </Grid>
        <div className="dashed-vertical-line"></div>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          style={{ paddingBottom: 64, paddingTop: 32 }}
        >
          <Hidden smDown>
            <Grid
              item
              style={{ marginTop: 32, padding: "0px 16px" }}
              xs={12}
              md={6}
            >
              <img
                src={debt}
                height={425}
                alt="We know how to help you with debt"
              ></img>
            </Grid>
          </Hidden>
          <Grid
            item
            style={{ marginTop: 32, padding: "0px 16px" }}
            xs={12}
            md={4}
          >
            <Typography
              style={{
                color: "#28282a",
                fontWeight: "bold",
              }}
              color="inherit"
              variant="h4"
            >
              How we can help
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              Here's a few ways I can help you with your debt:
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              <strong>Chapter 7 Bankruptcy</strong> - the most common form of
              bankruptcy, results in a complete discharge of your debts.
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              <strong>Chapter 13 Bankruptcy</strong> - This is a reorganization
              and debt repayment plan that provides a fresh start while also
              protecting your assets.
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              <strong>Credit Card And Medical Debt</strong> - Bankruptcy can
              discharge your credit card debt, medical bills and other unsecured
              debts.
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              <strong>Mortgage And Loan Modifications</strong> - Filing for
              bankruptcy immediately stops any foreclosure actions. The
              bankruptcy process can then be utilized to modify the terms your
              mortgage(s).
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              Bankruptcy can also help restore driving privileges by eliminating
              motor vehicle surcharges and possibly eliminate IRS liability
            </Typography>
          </Grid>
        </Grid>
        <Typography
          style={{
            color: "black",
            paddingTop: 16,
            maxWidth: "60%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="inherit"
          variant="h6"
        >
          <div style={{ display: "inline" }}>
            Bankruptcy gives you an opportunity to eliminate financial debt and
            rebuild your credit score. I can help lead you towards a{" "}
            <strong>better tomorrow.</strong>
          </div>
        </Typography>
      </section>
    );
  }
}

export default BankruptcyContent;
