"use-es6";

import React, { Component } from "react";
import { Backdrop, Grid, Link } from "@material-ui/core";

class MobileBackdrop extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open } = this.props;
    return (
      <Backdrop
        open={open}
        style={{ zIndex: 1099, backgroundColor: "rgba(0, 0, 0, .85)" }}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          direction="column"
          style={{ color: "white" }}
        >
          <Grid item style={{ paddingBottom: 24 }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/criminal"
            >
              {"Criminal"}
              <span
                style={{
                  height: 4,
                  width: 75,
                  display: "block",
                  margin: "4px auto 0",
                  backgroundColor: "#4355a3",
                }}
              />
            </Link>
          </Grid>
          <Grid item style={{ paddingBottom: 24 }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/bankruptcy"
            >
              {"Bankruptcy"}
            </Link>
            <span
              style={{
                height: 4,
                width: 75,
                display: "block",
                margin: "4px auto 0",
                backgroundColor: "#4355a3",
              }}
            />
          </Grid>
          <Grid item style={{ paddingBottom: 24 }}>
            <Link color="inherit" variant="h6" underline="none" href="/divorce">
              {"Divorce"}
            </Link>
            <span
              style={{
                height: 4,
                width: 75,
                display: "block",
                margin: "4px auto 0",
                backgroundColor: "#4355a3",
              }}
            />
          </Grid>
          <Grid item>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="/contactUs"
            >
              {"Contact Us"}
            </Link>
            <span
              style={{
                height: 4,
                width: 75,
                display: "block",
                margin: "4px auto 0",
                backgroundColor: "#4355a3",
              }}
            />
          </Grid>
        </Grid>
      </Backdrop>
    );
  }
}

export default MobileBackdrop;
