"use-es6";

import React, { Component } from "react";
import { Grid, Typography, Container, Hidden, Button } from "@material-ui/core";
import Meeting from "../Images/meeting.jpg";

class CTA extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container
        component="section"
        style={{
          marginTop: 88,
          marginBottom: 0,
          display: "flex",
          backgroundColor: "#fff5fa",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} style={{ zIndex: 1 }}>
            <div className="cta-card">
              <div className="cta-card-content">
                <Typography
                  variant="h3"
                  gutterBottom
                  style={{ fontSize: "2.5rem" }}
                >
                  Free Consultations
                </Typography>
                <Typography variant="h5" style={{ paddingBottom: 24 }}>
                  With free consultations, we welcome the opportunity to speak
                  with you and discuss how we may be of help.
                </Typography>
                <Button
                  color="inherit"
                  variant="contained"
                  style={{
                    width: "100%",
                    backgroundColor: "#28282a",
                    color: "white",
                  }}
                  href="/contactUs"
                >
                  CONTACT US
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ position: "relative" }}>
            <Hidden smDown>
              <div className="image-dots" />
              <img src={Meeting} alt="Contact Us" className="cta-image" />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default CTA;
