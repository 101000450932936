"use-es6";

import React, { Component } from "react";
import { Typography, Container, Button } from "@material-ui/core";

class ContactUs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 80,
          marginBottom: 72,
        }}
        component="section"
      >
        <Button
          style={{
            border: "4px solid black",
            borderRadius: 0,
            height: "auto",
            padding: "16px 40px",
          }}
          href="/contactUs"
        >
          <Typography variant="h4" component="span">
            Need legal help?
          </Typography>
        </Button>
        <Typography
          variant="h6"
          style={{ marginTop: 16, marginBottom: 24, color: "#28282a" }}
        >
          Rush Law Offices is here to help!
        </Typography>
      </Container>
    );
  }
}

export default ContactUs;
