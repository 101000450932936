"use-es6";

import React, { Component } from "react";
import HeroSection from "../Components/HeroSection";
import { Typography } from "@material-ui/core";

class HeroContainer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <HeroSection
        style={{ color: "white" }}
        backgroundClass="background-class"
      >
        <Typography
          style={{ color: "white" }}
          color="inherit"
          align="center"
          variant="h2"
          className="hero-main"
        >
          An Attornery with Experience
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          className="byline"
          style={{ color: "white", marginBottom: 32, marginTop: 80 }}
        >
          Whether it is a DUI, divorce, or drug charge, our legal experience at
          Rush Law Offices will be the solution you need.
        </Typography>
        <Typography
          variant="subtitle1"
          color="inherit"
          align="center"
          className="more"
          style={{ color: "white", marginTop: 16 }}
        >
          Read more below or just give us a call
        </Typography>
      </HeroSection>
    );
  }
}

export default HeroContainer;
