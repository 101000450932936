"use-es6";

import React, { Component } from "react";
import HeroSection from "../Components/HeroSection";
import { Typography } from "@material-ui/core";

class BankruptcyHeroContainer extends Component {
  render() {
    return (
      <HeroSection
        style={{ color: "white" }}
        backgroundClass="bank-background-class"
      >
        <Typography
          style={{ color: "white" }}
          color="inherit"
          align="center"
          variant="h2"
        >
          Bankruptcy can be Tough
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          className="byline"
          style={{ color: "white", marginBottom: 32, marginTop: 80 }}
        >
          Rush Law Offices has the experience to guide you through it
        </Typography>
      </HeroSection>
    );
  }
}

export default BankruptcyHeroContainer;
