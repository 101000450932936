"use-es6";

import React, { Component } from "react";
import Container from "@material-ui/core/Container";

class HeroSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="hero-section">
        <Container>
          {this.props.children}
          <div className="backdrop" />
          <div className={this.props.backgroundClass} />
        </Container>
      </section>
    );
  }
}

export default HeroSection;
