"use-es6";

import React, { Component } from "react";
import HeroSection from "../Components/HeroSection";
import { Typography } from "@material-ui/core";

class DDUIHeroContainer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <HeroSection
        style={{ color: "white" }}
        backgroundClass="crim-background-class"
      >
        <Typography
          style={{ color: "white" }}
          color="inherit"
          align="center"
          variant="h2"
          className="hero-main"
        >
          Criminal Charges can be Life Changing
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          className="byline"
          style={{ color: "white", marginBottom: 32, marginTop: 80 }}
        >
          Rush Law Offices has the experience to help you through them
        </Typography>
      </HeroSection>
    );
  }
}

export default DDUIHeroContainer;
