"use-es6";

import React, { Component } from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";
import signing from "../Images/signing.jpg";

class MeetAttorney extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="meet-attorney">
        <Typography
          style={{ color: "white", fontWeight: "bold", fontSize: "2.25rem" }}
          color="inherit"
          variant="h3"
          align="center"
        >
          Meet Your Attorney
          <span
            style={{
              height: 4,
              width: 155,
              display: "block",
              margin: "8px auto 0",
              backgroundColor: "#4355a3",
            }}
          />
        </Typography>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          style={{ paddingBottom: 64 }}
        >
          <Grid
            item
            style={{ marginTop: 32, padding: "0px 20px" }}
            xs={12}
            md={4}
          >
            <Typography
              style={{
                color: "white",
                fontWeight: "bold",
              }}
              color="inherit"
              variant="h5"
            >
              <u>William J. Rush</u>
            </Typography>
            <Typography style={{ color: "white" }} color="inherit" variant="h6">
              William J. Rush is recognized as an extensively experienced senior
              litigator for both criminal and civil matters, showcasing over
              twelve years of proven success in bench and jury trials, winning
              in court and at the negotiating table. His extensive leadership
              and legal skills are coupled with a seasoned, winning approach to
              business, contract and settlement negotiations. As a former
              municipal court judge, Mr. Rush's unique insight to legal
              procedure is an advantage that is exercised in every matter.
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: 32 }} xs={12} md={4}>
            <Hidden smDown>
              <img src={signing} height={300} alt="Meet your attorney"></img>
            </Hidden>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default MeetAttorney;
