"use-es6";

import React, { Component } from "react";
import { Grid, Typography, Hidden } from "@material-ui/core";
import papers from "../Images/div-papers.jpg";
import exper from "../Images/exper.jpg";

class DivorceContent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <section style={{ paddingTop: 64, paddingBottom: 64 }}>
        <Typography
          style={{ color: "black", fontWeight: "bold" }}
          color="inherit"
          variant="h3"
          align="center"
        >
          Here to help
          <span
            style={{
              height: 4,
              width: 155,
              display: "block",
              margin: "8px auto 0",
              backgroundColor: "#4355a3",
            }}
          />
        </Typography>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          style={{ paddingBottom: 64 }}
        >
          <Grid
            item
            style={{ marginTop: 32, padding: "0px 20px" }}
            xs={12}
            md={4}
          >
            <Typography
              style={{
                color: "#28282a",
                fontWeight: "bold",
              }}
              color="inherit"
              variant="h4"
            >
              Guidance you need
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              You may be experiencing a great deal of emotional hardship due to
              this turn of events in your life. When facing divorce or another
              serious family law matter, it is vital that you have skilled
              representation on your side that can guide you through the legal
              process and help you pursue the results that you need. Deciding
              which divorce attorney to retain for representation can be a
              difficult process; you want an experienced lawyer on your side,
              but one that also exemplifies care and compassion.
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              style={{ marginTop: 32, padding: "0px 20px" }}
              xs={12}
              md={4}
            >
              <img
                src={papers}
                height={300}
                alt="We can give you guidance"
              ></img>
            </Grid>
          </Hidden>
        </Grid>
        <div className="dashed-vertical-line"></div>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          style={{ paddingBottom: 64, paddingTop: 32 }}
        >
          <Hidden smDown>
            <Grid
              item
              style={{ marginTop: 32, padding: "0px 20px", marginRight: 16 }}
              xs={12}
              md={4}
            >
              <img
                src={exper}
                height={300}
                alt="We have the experience you need"
              ></img>
            </Grid>
          </Hidden>
          <Grid
            item
            style={{ marginTop: 32, padding: "0px 20px" }}
            xs={12}
            md={4}
          >
            <Typography
              style={{
                color: "#28282a",
                fontWeight: "bold",
              }}
              color="inherit"
              variant="h4"
            >
              Experience you can trust
            </Typography>
            <Typography style={{ color: "black" }} color="inherit" variant="h6">
              At Rush Law Offices, you can be sure that we have the experience
              you need and we are dedicated to showing compassion to our clients
              and putting your needs first. We have extensive experience serving
              the residents of Passaic County, and throughout New Jersey in
              various legal matters. We can put this experience to work for you!
              Whether you are facing divorce or you are suffering from an issue
              regarding child custody, William J. Rush is prepared to assist you
              today.
            </Typography>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DivorceContent;
