"use-es6";

import React, { Component } from "react";
import LandingAppBar from "../Components/LandingAppBar";
import ContactForm from "../Components/ContactForm";
import PageFooter from "../Components/PageFooter";
import Legal from "../Components/Legal";

class ContactUsContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <LandingAppBar />
        <ContactForm />
        <PageFooter />
        <Legal />
      </div>
    );
  }
}

export default ContactUsContainer;
