"use-es6";

import React, { Component } from "react";
import { Grid, Typography, Container } from "@material-ui/core";

class Legal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container style={{ marginTop: 56, marginBottom: 64, display: "flex" }}>
        <Grid
          container
          style={{
            color: "#333",
            flexDirection: "column",
          }}
        >
          <Typography
            fontSize={6}
            style={{ color: "#333", maxWidth: 800, marginBottom: 8 }}
          >
            The information contained in this website is provided for
            informational purposes only, and should not be construed as legal
            advice on any subject matter. No recipients of content from this
            site, clients or otherwise, should act or refrain from acting on the
            basis of any content included in the site without seeking the
            appropriate legal or other professional advice on the particular
            facts and circumstances at issue from an attorney licensed in the
            state of New Jersey. The content of this website contains general
            information and may not reflect current legal developments, verdicts
            or settlements. William J. Rush, Attorney at Law expressly disclaims
            all liability in respect to actions taken or not taken based on any
            or all the contents of this website.
          </Typography>
          <Typography
            fontSize={6}
            style={{ color: "#333", maxWidth: 800, marginBottom: 8 }}
          >
            Any information sent to William J. Rush, Attorney at Law by Internet
            e-mail or through the website is not secure and is done so on a
            non-confidential basis. Transmission of information from this
            Website does not create an attorney-client relationship between you
            and William J. Rush, Attorney at Law, nor is it intended to do so.
            The transmission of the website, in part or in whole, and/or any
            communication with us via Internet e-mail through this site does not
            constitute or create an attorney-client relationship between us and
            any recipients.
          </Typography>
          <Typography
            fontSize={6}
            style={{ color: "#333", maxWidth: 800, marginBottom: 8 }}
          >
            Some links within the website may lead to other websites, including
            those operated and maintained by third parties. William J. Rush,
            Attorney at Law includes these links solely as a convenience to you,
            and the presence of such a link does not imply a responsibility for
            the linked site or an endorsement of the linked site, its operator,
            or its contents.
          </Typography>
          <Typography
            fontSize={6}
            style={{ color: "#333", maxWidth: 800, marginBottom: 8 }}
          >
            His website and its contents are provided “AS IS” without warranty
            of any kind, either expressed or implied, including, but not limited
            to, the implied warranties of merchantability, fitness for a
            particular purpose, or non-infringement.
          </Typography>
          <Typography
            fontSize={6}
            style={{ color: "#333", maxWidth: 800, marginBottom: 8 }}
          >
            Furthermore, William J. Rush, Attorney at Law does not wish to
            represent anyone desiring representation based upon viewing this
            website in a state where this website fails to comply with all laws
            and ethical rules of that state.
          </Typography>
          <Typography
            fontSize={6}
            style={{ color: "#333", maxWidth: 800, marginBottom: 8 }}
          >
            Reproduction, distribution, republication, and/or retransmission of
            text contained within the rushlaw.org Website is prohibited unless
            the prior written permission of William J. Rush, Attorney at Law has
            been obtained
          </Typography>
          <Typography
            fontSize={6}
            style={{ color: "#333", maxWidth: 800, marginBottom: 8 }}
          >
            © 2020 Rush Law Offices
          </Typography>
        </Grid>
      </Container>
    );
  }
}

export default Legal;
