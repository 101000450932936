"use-es6";

import React, { Component } from "react";
import HeroSection from "../Components/HeroSection";
import { Typography } from "@material-ui/core";

class DivorceHeroContainer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <HeroSection
        style={{ color: "white" }}
        backgroundClass="divorce-background-class"
      >
        <Typography
          style={{ color: "white" }}
          color="inherit"
          align="center"
          variant="h2"
        >
          A Divorce can be Rocky
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          className="byline"
          style={{ color: "white", marginBottom: 32, marginTop: 80 }}
        >
          Rush Law Offices has the experience to guide you through these
          difficult times
        </Typography>
      </HeroSection>
    );
  }
}

export default DivorceHeroContainer;
