import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import LandingPageContainer from "./Containers/LandingPageContainer";
import ContactUsContainer from "./Containers/ContactUsContainer";
import DivorceContainer from "./Containers/DivorceContainer";
import BankruptcyContainer from "./Containers/BankruptcyContainer";
import DDUIContainer from "./Containers/DDUIContainer";
import "./App.css";

class App extends Component {
  /*return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );*/
  render() {
    return (
      <BrowserRouter>
        <div className="content">
          <Route path="/" exact component={LandingPageContainer} />
          <Route path="/contactUs" exact component={ContactUsContainer} />
          <Route path="/divorce" exact component={DivorceContainer} />
          <Route path="/bankruptcy" exact component={BankruptcyContainer} />
          <Route path="/criminal" exact component={DDUIContainer} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
