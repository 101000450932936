import React, { Component } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open } = this.props;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        aria-labelledby="confirmation dialog"
        open={open}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent style={{ paddingBottom: 8 }}>
          <Typography variant="h6">
            Your contact request has been sent! Thanks for reaching out!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            style={{ backgroundColor: "#4355a3", color: "white" }}
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;
