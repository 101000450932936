"use-es6";

import React, { Component } from "react";
import { Grid, Typography, Container, Hidden } from "@material-ui/core";

class PageFooter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Typography
        component="footer"
        style={{
          color: "white",
          backgroundColor: "#28282a",
          width: "100%",
          display: "flex",
        }}
      >
        <Container style={{ marginTop: 64, marginBottom: 64, display: "flex" }}>
          <Grid container spacing={6} justify="space-evenly">
            <Grid item xs={12} sm={4} md={3}>
              <Grid
                container
                direction="column"
                justify="flex-end"
                style={{}}
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h4">
                    <strong>CONTACT US</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Rush Law Offices</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    435 Ridgedale Ave, #1
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    East Hanover, NJ 07936
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Tel: 201-372-1006 | 201-401-6538
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Email: wjrush@rushlaw.org
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} sm={4} md={3}>
                <Grid
                  container
                  direction="column"
                  justify="flex-end"
                  style={{}}
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="h4">
                      <strong>MAP</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <iframe
                      width="300"
                      height="250"
                      frameborder="0"
                      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA1MFYbAg_0NrjLxJgEquc-UyXPuwj9Y2w&q=Rush+Law+Offices,East+Hanover,NJ"
                      allowfullscreen
                    ></iframe>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={4} md={3}>
              <Grid
                container
                direction="column"
                justify="flex-end"
                style={{}}
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h4">
                    <strong>BUSINESS HOURS</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Monday: 9:00 AM - 5:00 PM
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Tuesday: 9:00 AM - 5:00 PM
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Wednesday: 9:00 AM - 5:00 PM
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Thursday: 9:00 AM - 5:00 PM
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Friday: 9:00 AM - 5:00 PM
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Saturday: By Appointment
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">Sunday: Closed</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Typography>
    );
  }
}

export default PageFooter;
