"use-es6";

import React, { Component } from "react";
import LandingAppBar from "../Components/LandingAppBar";
import HeroContainer from "./HeroContainer";
import FocusAreas from "../Components/FocusAreas";
import MeetAttorney from "../Components/MeetAttorney";
import CTA from "../Components/CTA";
import ContactUs from "../Components/ContactUs";
import PageFooter from "../Components/PageFooter";
import Legal from "../Components/Legal";

class LandingPageContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <LandingAppBar />
        <HeroContainer />
        <FocusAreas />
        <MeetAttorney />
        <CTA />
        <ContactUs />
        <PageFooter />
        <Legal />
      </div>
    );
  }
}

export default LandingPageContainer;
