"use-es6";

import React, { Component } from "react";
import LandingAppBar from "../Components/LandingAppBar";
import BankruptcyHeroContainer from "./BankruptcyHeroContainer";
import BankruptcyContent from "../Components/BankruptcyContent";
import PageFooter from "../Components/PageFooter";
import Legal from "../Components/Legal";

class BankruptcyContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <LandingAppBar />
        <BankruptcyHeroContainer />
        <BankruptcyContent />
        <PageFooter />
        <Legal />
      </div>
    );
  }
}

export default BankruptcyContainer;
