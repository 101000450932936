"use-es6";

import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import WJR from "../Images/WJR.svg";
import Burger from "@animated-burgers/burger-slip";
import "@animated-burgers/burger-slip/dist/styles.css";
import { Hidden } from "@material-ui/core";
import MobileBackdrop from "./MobileBackdrop";

class LandingAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: false,
    };
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  handleOverlayClick() {
    const { overlay } = this.state;
    this.setState({ overlay: !overlay });
  }

  render() {
    const { overlay } = this.state;
    return (
      <div>
        <AppBar position="fixed" style={{ backgroundColor: "#28282a" }}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div style={{ flex: 1 }} />
            <Link
              color="inherit"
              variant="h4"
              underline="none"
              className="title"
              href="/"
              style={{
                color: "#e3e1e1",
                display: "flex",
                alignContent: "center",
                verticalAlign: "middle",
              }}
            >
              <img src={WJR} alt="Logo" />
              <strong style={{ color: "white", paddingLeft: 16 }}>
                Rush
              </strong>{" "}
              Law
            </Link>
            <Grid container justify="flex-end" alignItems="center" spacing={2}>
              <Hidden smDown>
                <Grid item className="right-app-bar-link">
                  <Link
                    color="inherit"
                    variant="h6"
                    underline="none"
                    href="/criminal"
                  >
                    {"Criminal"}
                  </Link>
                </Grid>
                <Grid item className="right-app-bar-link">
                  <Link
                    color="inherit"
                    variant="h6"
                    underline="none"
                    href="/bankruptcy"
                  >
                    {"Bankruptcy"}
                  </Link>
                </Grid>
                <Grid item className="right-app-bar-link">
                  <Link
                    color="inherit"
                    variant="h6"
                    underline="none"
                    href="/divorce"
                  >
                    {"Divorce"}
                  </Link>
                </Grid>
                <Grid item className="right-app-bar-link">
                  <Link
                    color="inherit"
                    variant="h6"
                    underline="none"
                    href="/contactUs"
                  >
                    {"Contact Us"}
                  </Link>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <div
                  style={{
                    backgroundColor: "#28282a",
                    marginRight: 8,
                    paddingRight: 8,
                  }}
                >
                  {/*<button
                    className="burger open"
                    style={{
                      backgroundColor: "#28282a",
                      borderColor: "#28282a",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: 10,
                    }}
                  >
                    <div className="burger-lines" />
                  </button>*/}
                  <Burger
                    Component="button"
                    type="button"
                    isOpen={overlay}
                    onClick={this.handleOverlayClick}
                    aria-label="More about us"
                    style={{ backgroundColor: "inherit", border: 0 }}
                  />
                </div>
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <MobileBackdrop open={overlay} />
      </div>
    );
  }
}

export default LandingAppBar;
